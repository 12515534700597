import utils from './utils';


const timeCard = {
  MESSAGE_INVALID_HOURS : 'Hours must be entered in quarter hour increments: .00, .25, .50 or .75',
  MESSAGE_INVALID_FLOAT : 'This entry must be a number, please correct the entry',

  dynamicTables : ['other_hours', 'expenses', 'tips'],

  /**
   * Hides/Unhides a time card depending on the href attributed
   * in link.   If successfull remove the li containing link.  Displays
   * a message retrieved from the server.
   *
   * @param {Object} link the anchor that was clicked on.
   * @return {Boolean}
   */
  toggleTimeCard : function(link) {
    $.getJSON($(link).attr('href') + "&format=json&jsoncallback=?",
      function(data){
        if (data.status == 1) {
          $(link).parents('tr').eq(0).empty();
        }
        alert(data.message);
      });
  },

  /**
   * Returns true if all the required fields have been filled out or
   * none of the required fields have been filled out.  False otherwise.
   * Required fields are indicated by the class "required" on the
   * containing td.
   *
   * @param {Object} row the table row to validate.
   * @return {Boolean}
   */
  validateRowRequiredFields : function(row) {
    if (timeCard.isRowEmpty(row)) {
      return true;
    } else {
      let isValid = true;
      $(row).find('td.required input[type="text"], td.required select')
        .each(function() {
          const $this = $(this);
          if ($this.val().length < 1) {
            isValid = false;
            $this.addClass('validation_error');
          }
        });

      return isValid;
    }
  },

  /**
   * Validates the table with id tableId has all required fields filled
   * out.  Returns true if there are no errors and false otherwise.
   * @param {string} tableId
   * @return boolean
   */
  validateTableRequiredFields : function (tableId) {
    var hasErrors = false;
    $('#' + tableId + ' tbody tr').each(function () {
      var e = timeCard.validateRowRequiredFields(this);
      if (!e) {
        hasErrors = true;
      }
    });

    if (hasErrors) {
        return false;
    }

    return true;
  },

  /**
   * Returns true if row is empty and false otherwise.  row is defined
   * to be empty if all the required fields are empty.
   * $param {object} row the table row to be examined
   * @return {boolean}
   */
  isRowEmpty : function(row) {
    var isEmpty = true;
    $(row).find('td.required input[type="text"], td.required select').each(function() {
      if (($(this).val().length > 0) && ($(this).is(":visible"))){
        isEmpty = false;
      }
    });

    return isEmpty;
  },

  /**
   * Removes empty rows from the table with id tableId
   * $param {string} tableId
   * @return {void}
   */
  removeEmptyRows : function (tableId) {
    $('#' + tableId + ' tbody tr').each(function () {
      if (timeCard.isRowEmpty(this)) {
        $(this).remove();
      }
    });

    timeCard.totalTable('tableId');
  },

  /**
   * Display the error dialog box with the message, msg.
   * @param {string} msg
   * @param {object} pos Must contain 2 properties left and top.  If
   * present the dialog is positioned based on the left and top
   * properties of pos.
   * @return {void}
   */
  displayErrorDialog : function (msg, pos) {
    $("#general_error").dialog('destroy');
    $("#general_error p span").html(msg);

    if (typeof pos == "undefined") {
      $("#general_error").dialog();
    } else {
      $("#general_error").dialog({ 'position': [pos.left, pos.top] });
    }

    $("#general_error").dialog("open");
  },

  /**
   * Computes and sets the daily totals for the course_hours table.
   * @return void
   */
  computeDayTotals : function() {
    var runningTotal = 0;
    var runningDriveTotal = 0;
    var runningModifiedTotal = 0;

    /**
     * An array of the modified hours td's for a particular day
     */
    var componentSessionModifiedHours = [];
    var minHoursPerDay = 0;
    var modifiedHours = 0;
    var isView = true;
    if ($('#time_card_form').length > 0) {
      isView = false;
      minHoursPerDay = $('#time_card_form input[name="daily_minimum"]').val();
    }

      $('#course_hours tbody tr').each(function() {
        var $this = $(this);
        if ($this.hasClass('day_total')) {
          if (isView) {
            //if this is a view then use the running total of the modified hours for the modified total
            timeCard.setDayTotals(this, runningTotal, runningModifiedTotal, runningDriveTotal);

          } else {
            if (runningTotal < (minHoursPerDay * 100)) {
              //if this isn't a view and the total hours is less than the minimum number of hours
              //then use the difference as the modified total
              modifiedHours = (minHoursPerDay * 100) - (runningTotal);
            } else {
              modifiedHours = 0;
            }

            timeCard.setDayTotals(this, runningTotal, modifiedHours, runningDriveTotal);
            //Go back and set the modified hour hidden input elements to reflect the amount
            //of modification
            timeCard.setModifiedHours(componentSessionModifiedHours, modifiedHours);
          }

          runningTotal = 0;
          runningDriveTotal = 0;
          componentSessionModifiedHours = [];
          runningModifiedTotal = 0;
        } else {
          if (isView) {
            runningModifiedTotal += (timeCard.getCellValue($this.find('td.modified_col').get(0)) * 100);
           }

          componentSessionModifiedHours.push($this.find('td.modified_col'));
          runningTotal += (timeCard.getCellValue($this.find('td.total_col').get(0)) * 100);
          runningDriveTotal += (timeCard.getCellValue($this.find('td.drive_time_col').get(0)) * 100);
        }
      });
  },

  /**
   * Set the day total and day drive time total in row.
   * @param {Object} row
   * @param {number} total the total hours for the day
   * @param {number} modifiedTotal the amount of modification to apply
   * to total.  If this is great than 0 the class modified_total will
   * be added to the total_col td
   * @param {number} driveTotal the total drive time for the day
   * @return {void}
   */
  setDayTotals : function (row, total, modifiedTotal, driveTotal) {
    var $row = $(row);
    var $totalCol = $row.find('td.total_col');

    if (modifiedTotal > 0) {
      total = (total + modifiedTotal)/100;
      $totalCol.addClass('modified_total');
    } else {
      total = total / 100;
      $totalCol.removeClass('modified_total');
    }

    $totalCol.html(timeCard.formatCurrency(total));
    $row.find('td.drive_time_col').html(timeCard.formatCurrency((driveTotal/100)));
  },

  /**
   * Takes value and divides it equally among the elements of
   * modifiedHourCells, setting both the span.number and the input
   * element in each cell.
   *
   * @param {Array} modifiedHourCells
   * @param {number} value 
   * @return {void}
   */
  setModifiedHours : function (modifiedHourCells, value) {
    var individualModifierBase = Math.floor(value / modifiedHourCells.length);
    var numberPlusOneModifiers = value - (individualModifierBase * modifiedHourCells.length);
    var modifiedValue = 0;

    for (var i = 0; i < modifiedHourCells.length; i++) {
      if (numberPlusOneModifiers > 0) {
        modifiedValue = (individualModifierBase + 1) / 100;
        numberPlusOneModifiers--;
      } else {
        modifiedValue = individualModifierBase / 100;
      }

      modifiedHourCells[i].find('input').val(modifiedValue);
      modifiedHourCells[i].find('.number').html(modifiedValue);
    }
  },

  /**
   * Updates the totals for each row of the table identified by tableId
   * @param {String} tableId The id for the table to total
   * @param {String} cssClassElements The css class identifying the columns to total
   * @param {String} cssClassTotalColumn The css class identifying the column to place the total in
   * @return void
   */
  totalRows : function(tableId, cssClassElements, cssClassTotalColumn) {
    $('#' + tableId + ' tbody tr').each(function () {
      timeCard.totalRow(this, cssClassElements, cssClassTotalColumn);
    });
  },

  /**
   * Updates the total column for row
   * @param {Object} row The row to total
   * @param {String} cssClassElements The css class identifying the columns to total
   * @param {String} cssClassTotalColumn The css class identifying the column to place the total in
   * @return void
   */
  totalRow : function (row, cssClassElements, cssClassTotalColumn) {
    var total = 0.0;
    var $row = $(row);

    $row .find('td.' + cssClassElements).each(function () {
      total += timeCard.getCellValue(this);
    });

    $row.find('td.' + cssClassTotalColumn + ' span.number').eq(0).
    html(timeCard.formatCurrency(total));
  },

  /**
   * Gets the value from a table cell.  If the cell has an input in it
   * retrieve the value of the input, or if there is a span with the class
   * number retrieve the innerHTML of that span otherwise retrieve the
   * innerHTML of the table cell.  If the value found isn't a number
   * 0 is returned.
   * @param {Object} cell
   * @return number
   */
  getCellValue : function (cell) {
    var value = 0;
    var testValue;
    var $cell = $(cell);

    if ($cell.find('input[type="text"]').size() > 0) {
      testValue = $cell.find('input').get(0).value;
    } else if($cell.find('span.number').size() > 0) {
      testValue = $cell.find('span.number').get(0).innerHTML;
    } else {
      testValue = cell.innerHTML;
    }

    if (testValue) {
      testValue = testValue.replace(/\,/g,'');
      if (utils.validateNumber(jQuery.trim(testValue))) {
        value = jQuery.trim(testValue);
      }
    }

    return +value;
  },

  /**
   * Gets the innerHtml from a cell.
   *
   * @param {Object} cell
   * @return string
   */
  getCellText : function (cell) {
    var text = '';
    var $cell = $(cell);

    if ($cell.find('input[type="text"]').size() > 0) {
      text = $cell.find('input').eq(0).val();
    } else if($cell.find('span.text').size() > 0) {
      text = $cell.find('span.text').eq(0).html();
    }

    return text;
  },

  /**
   * Update the total for the table identified by tableId
   * @param {String} tableId
   * @return void
   */
  totalTable : function (tableId) {
    var total = 0;

    if ($('#' + tableId + ' tbody tr.day_total').length > 0 ) {
      //special case for the course hours table
      timeCard.computeDayTotals();

      $('#' + tableId + ' tbody tr.day_total td.total_col').each(function () {
        total += timeCard.getCellValue(this);
      });

    } else {
      $('#' + tableId + ' tbody td.total_col').each(function () {
        total += timeCard.getCellValue(this);
      });

      if (tableId === 'other_hours') {
        timeCard.highlightOtherHoursDailyOverTime();
      }
    }

    $('#' + tableId + ' tfoot th.total_col span.number').
    html(timeCard.formatCurrency(total));
    timeCard.totalSummary();
  },

  /**
   * Update the totals in the summary table.
   * @return void
   */
  totalSummary : function () {
      var total = 0;
      var hourlyRate = 0;
      var grossPay = 0;
      var tips = 0;
      var expenses = 0;
      var hourlyRateString = '';

    if ($('#summary_gross_pay').size() > 0) {
      total += +$('#course_hours tfoot th.total_col span.number').html();
      total += +$('#other_hours tfoot th.total_col span.number').html();
      $('#summary_total_hours').html(timeCard.formatCurrency(total));

      tips = timeCard.getCellValue('#tips tfoot th.total_col');
      $('#summary_tips span.number').html(timeCard.formatCurrency(tips));

      expenses = timeCard.getCellValue('#expenses tfoot th.total_col');
      $('#summary_expenses span.number').html(timeCard.formatCurrency(expenses));

      hourlyRateString = $('#summary_pay_rate').html();
      if (hourlyRateString.length > 0) {
          hourlyRate = +hourlyRateString.substr(1);
      }

      grossPay = (hourlyRate * total) + tips;
      $('#summary_gross_pay').html('$' + timeCard.formatCurrency(grossPay));
    }
  },

  /**
   * Validate the value in element using the function fn and if the
   * validation fails display msg.  Returns true if the validation passed
   * and false otherwise.
   *
   * @param {Object} element
   * @param {Function} fn
   * @param {String} msg
   * @return {Boolean}
   */
  validateElement : function (element, fn, msg) {
    var j = $(element);
    var value = jQuery.trim(j.val());

    if (value.length > 0) {
      if (fn(value)) {
        j.removeClass('validation_error');
        return true;
      } else {
        timeCard.displayValidationError(j, msg);
      }
    }

  return false;
  },

  /**
   * Displays the validation error on the jquery object $element with
   * message, msg.
   *
   * @param {jQuery} $element
   * @param {String} msg
   * @return {void}
   */
  displayValidationError : function ($element, msg) {
    if (!$element.hasClass('validation_error')) {
      $element.addClass('validation_error');
      var docViewTop = $(window).scrollTop();
      var elementTop = $element.position().top;
      var pos = { left: $element.position().left, top: elementTop - docViewTop + 20 };
      timeCard.displayErrorDialog(msg, pos);
    }
  },

  /**
   * Checks if the element's value is a valid float.  If its invalid
   * add the validation_error to the element and display the error dialog
   * @param {Object} element
   * @return {Boolean}
   */
  validateHourElement : function (element) {
    if (timeCard.validateFloatElement(element)) {
      return timeCard.validateElement(
        element,
        utils.validateTimeCardHour,
        timeCard.MESSAGE_INVALID_HOURS
      );
    } else {
      return false;
    }
  },

  /**
   * Checks if the element's value is a valid float.  If its invalid
   * add the validation_error to the element and display the error dialog
   * @param {Object} element
   * @return {Boolean}
   */
  validateFloatElement : function (element) {
    return timeCard.validateElement(
      element,
      utils.validateNumber,
      timeCard.MESSAGE_INVALID_FLOAT
    );
  },

  /**
   * Setup the comment icons and the comment dialog
   *
   * @return void
   */
  initializeComments : function () {
    //initialize the dialog.
    if (typeof $("#dialog_comment_form").dialog != "undefined") {
      $("#dialog_comment_form").dialog({
        bgiframe: true,
        autoOpen: false,
        height: 200,
        modal: true
      });
    }

    //initialize the icons
    $("img.add_comment").each(function() {
      var sessionId = $(this).metadata().session_id;
      var comment = $('#session_comments_' + sessionId).val();
      timeCard.setComment(sessionId, comment);
    });

    //bind events
    $('img.add_comment').click(function() {
      var sessionId = $(this).metadata().session_id;
      timeCard.showAddComment(sessionId);
    });

    $('img.edit_comment').click(function() {
      var sessionId = $(this).metadata().session_id;
      timeCard.showEditComment(sessionId);
    });
  },

  /**
   * Sets the comment for the session corresponding to sessionId to
   * comment and show the edit/add icon depending on if the comment
   * is present or not.
   *
   * @param {String, Number} sessionId
   * @param {String} comment
   * @return void
   */
  setComment : function (sessionId, comment) {
    if (comment.length > 0) {
      $("#image_add_session_comment_" + sessionId).hide();
      $("#image_edit_session_comment_" + sessionId).show();
      $("#image_add_session_comment_" + sessionId).parent("td:last").addClass('comment_present');
    } else {
      $("#image_add_session_comment_" + sessionId).show();
      $("#image_edit_session_comment_" + sessionId).hide();
      $("#image_add_session_comment_" + sessionId).parent("td:last").removeClass('comment_present');
    }

    $('#session_comments_' + sessionId).val(comment);
    timeCard.setEditCommentIconTitle(sessionId);
  },

  /**
   * Sets the title attribute of the edit icon to be a short version
   * of the actual comment
   *
   * @param {String, Number} sessionId
   * @return void
   */
  setEditCommentIconTitle : function (sessionId) {
    var commentText = $('#session_comments_' + sessionId).val();
    if (commentText.length > 30) {
      commentText = commentText.substr(0, 27) + "...";
    }

    $("#image_edit_session_comment_" + sessionId).attr("title", commentText);
  },

  /**
   * Display the dialog to add a comment
   *
   * @param {String, Number} sessionId
   * @return void
   */
  showAddComment : function (sessionId) {
    $("#dialog_comment_form input[name='session_id']").val(sessionId);
    $("#dialog_comment_form textarea[name='comment']").val("");

    var pos = $("#image_add_session_comment_" + sessionId).position();
    var docViewTop = $(window).scrollTop();
    $("#dialog_comment_form").dialog('option', 'position', [pos.left - 300, pos.top - docViewTop + 20]);
    $("#dialog_comment_form").dialog('option', 'buttons', {
      'Add Note': function() {
        var comment = $(this).find('textarea[name="comment"]').val();
        var sessionId = $(this).find('input[name="session_id"]').val();
        timeCard.setComment(sessionId, comment);
        $(this).dialog('close');
      },

      'Cancel': function() {
        $(this).dialog('close');
      }
    });

    $("#dialog_comment_form").dialog('open');
  },

  /**
   * Display the dialog to edit a comment
   *
   * @param {String, Number} sessionId
   * @return void
   */
  showEditComment : function (sessionId) {
    $("#dialog_comment_form input[name='session_id']").val(sessionId);
    $("#dialog_comment_form textarea[name='comment']").val($('#session_comments_' + sessionId).val());

    var pos = $("#image_edit_session_comment_" + sessionId).position();
    var docViewTop = $(window).scrollTop();
    $("#dialog_comment_form").dialog('option', 'position', [pos.left - 300, pos.top - docViewTop + 20]);
    $("#dialog_comment_form").dialog('option', 'buttons', {
        'Save': function() {
          var comment = $(this).find('textarea[name="comment"]').val();
          var sessionId = $(this).find('input[name="session_id"]').val();
          timeCard.setComment(sessionId, comment);
          $(this).dialog('close');
        },

        'Clear': function() {
          var sessionId = $(this).find('input[name="session_id"]').val();
          timeCard.setComment(sessionId, "");
          $(this).dialog('close');
        },

        'Cancel': function() {
          $(this).dialog('close');
        }
      });

    $("#dialog_comment_form").dialog('open');
  },

  /**
   * Add a row to a table
   *
   * @param String tableId The table to add the row to
   * @return void
   */
  addTableRow : function (tableId) {
    var newRow = $('#' + tableId + ' tr.template').clone();

    //set the tab indexes
    var existingFormElements = $('#' + tableId + ' input[tabindex]:last, #' + tableId + ' select[tabindex]:last');
    var lastItem = existingFormElements.eq(existingFormElements.length - 1);
    var lastTabindex = (+lastItem.attr('tabindex')) + 1;
    newRow.find('[tabindex]').each(function() {
      $(this).attr('tabindex', lastTabindex++);
    });

    //transform the template into a row
    newRow.removeClass('template');
    newRow.find('input, select').removeAttr('disabled');
    newRow.find('input.transform_to_date_input').addClass('date_input').removeClass('transform_to_date_input').datePicker({
      startDate:'01/01/1996'
    });

    //work around so the date button displays correctly
    var datebuttonhtml = '&nbsp;&nbsp;&nbsp;&nbsp;';
    newRow.find('.dp-choose-date').html(datebuttonhtml);

    //bind events
    newRow.find('td.total_col input').change(function() {
      timeCard.totalTable(tableId);
    });

    newRow.find('input, select').blur(function () {
      $(this).removeClass('validation_error');
    });

    newRow.find("input.validate_float").blur(function() {
      timeCard.validateFloatElement(this);
    });

    newRow.find("input.validate_hour").blur(function() {
      timeCard.validateHourElement(this);
    });

    $('#' + tableId + ' tr:last').after(newRow);
    $('#' + tableId + ' tr:last input:first').focus();
  },

  /**
   * Display the dialog to deny a time card
   *
   * @return void
   */
  showDenyDialog : function () {
    //initialize the dialog.
    if (typeof $("#dialog_deny").dialog != "undefined") {
      $("#dialog_deny").dialog({
        bgiframe: true,
        autoOpen: false,
        height: 250,
        modal: true
      });
    }

    var pos = $("#deny_time_card").position();
    var docViewTop = $(window).scrollTop();
    $("#dialog_deny").dialog('option', 'position', [pos.left, pos.top - 500 - docViewTop]);
    $("#dialog_deny").dialog('option', 'buttons', {
      'Deny': function() {
        var comment = $(this).find('textarea[name="comment"]').val();
        $('input[name="deny_comment"]').val(comment);
        $('#time_card_form').append('<input type="hidden" name="deny" value="true" />');
        $('#time_card_form').submit();
        $(this).dialog('close');
      },

      'Cancel': function() {
        $(this).dialog('close');
      }
    });

    $("#dialog_deny").dialog('open');
  },

  /**
   * Handler for the time_card_form submit event.  Checks to make sure
   * all the fields are filled out and removes empty rows.  If there are
   * empty required fields returns false and true otherwise.
   *
   * @return {boolean}
   */
  processTimeCardSubmit : function() {
    //make sure that all the entries are completely filled out
    var isMissingRequired = false;
    for (var i = 0; i < timeCard.dynamicTables.length; i++) {
      if (!timeCard.validateTableRequiredFields(timeCard.dynamicTables[i])) {
        isMissingRequired = true;
      }
    }

    if (isMissingRequired) {
      var docViewTop = $(window).scrollTop();
      var submitButtonPosition = $('#time_card_form input[type="submit"]:last').position();
      var pos = { left: submitButtonPosition.left, top: submitButtonPosition.top - docViewTop - 150 };
      timeCard.displayErrorDialog('All fields marked with a * must be filled out', pos);
      return false;

    } else {
      //remove the blank rows
      for (i = 0; i < timeCard.dynamicTables.length; i++) {
        timeCard.removeEmptyRows(timeCard.dynamicTables[i]);
      }
      return true;
    }
  },

  /**
   * Highlights the hours cell of the #other_hours table if the
   * hours qualify for overtime.
   *
   */
  highlightOtherHoursDailyOverTime : function() {
    var $ohh = $('#other_hours_header');

    if ($ohh.length > 0) {
      var dailyOverTimeThreshold = $ohh.metadata().overtimeThreshold;
      var isOvertimeEligible = $ohh.metadata().overtimeEligble;

      if (isOvertimeEligible === 1) {
        $('#other_hours tbody tr').each(function() {

          var date = timeCard.getCellText($(this).find('td.date_col').get(0));
          if (date.length > 0) {

            var hoursCell = $(this).find('td.hours_col');
            var hours = timeCard.getCellValue(hoursCell.get(0));
            if (hours > dailyOverTimeThreshold) {
              hoursCell.addClass('overtime');
            } else {
              hoursCell.removeClass('overtime');
            }
          }
        });
      }
    }
  },

  /**
   * Takes a string representation of the numbers and adds
   * commas separators to the string.
   *
   * @param nStr String The number to add commas too
   * @return string
   */
  addCommas : function (nStr) {
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;

    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }

    return x1 + x2;
  },

  /**
   * Formats a number in the format x,xxx.xx.
   *
   * @param n Number
   * @return string
   */
  formatCurrency : function (n) {
    return timeCard.addCommas(n.toFixed(2));
  }
};

export default timeCard;
