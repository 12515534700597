import constants from './constants';


const resourceTypes = {
  [constants.RESOURCE_TYPE_INFO]: 'More Info',
  [constants.RESOURCE_TYPE_REGSITER]: 'Register thru Client',
  [constants.RESOURCE_TYPE_REGSITER_PLAYWELL]: 'Register thru Play Well',
};

export default resourceTypes;
