const utils = {
  /*
   * Automatically submit status filter forms and hide their
   * submit buttons.
   */
  autoSubmitSelectForms : function () {
    $('form.select_auto_submit select').change(function() {
      $(this).parents('form').eq(0).submit();
    });

    $('form.select_auto_submit input[type="submit"]').hide();
  },

  /**
   * Returns true if value is a valid number, and false otherwise
   * @param {String} value
   * @return Boolean
   */
  validateNumber : function (value) {
    var reFloat = /^((\d+(\.\d*)?)|((\d*\.)?\d+))$/;
    return reFloat.test(value);
  },

  /**
   * Returns true if value is a valid hours entry for a time card, and false otherwise
   * @param {String} value
   * @return Boolean
   */
  validateTimeCardHour : function (value) {
    if (utils.validateNumber(value)) {
      return ((value * 100 % 25) == 0);
    } else {
      return false;
    }
  },

  //TODO DOC
  getInnerAttributes : function() {
    var x,y;
    if (self.innerHeight) // all except Explorer
    {
      x = self.innerWidth;
      y = self.innerHeight;
    }
    else if (document.documentElement && document.documentElement.clientHeight)
    // Explorer 6 Strict Mode
    {
      x = document.documentElement.clientWidth;
      y = document.documentElement.clientHeight;
    }
    else if (document.body) // other Explorers
    {
      x = document.body.clientWidth;
      y = document.body.clientHeight;
    }
    return {
      innerWidth : x,
      innerHeight : y
    };
  },

  addInstructor : function() {
    var newInstructor = $('#instructors').find('li:first').clone();
    var numberOfInstructor = $('#instructors').find('li').size();

    //Remove any id's from the children of the new li
    newInstructor.children().removeAttr('id');
    //if any of the selects have an option that is selected remove that selection
    newInstructor.find('option').removeAttr('selected');

    //compute the new label for the field
    var newLabel = newInstructor.find('label').html();
    newLabel = newLabel.replace(/Instructor \d+/, 'Instructor ' + numberOfInstructor);
    newInstructor.find('label').html(newLabel);

    //insert the new li
    $('#add_instructor').before(newInstructor);
    return false;
  },

  highlightRowOnLink : function() {
    $('table.highlight_row_on_link tbody tr a').hover(function() {
      $(this).parents('tr').eq(0).addClass('hovered');
    }, function() {
      $(this).parents('tr').eq(0).removeClass('hovered');
    });
  },

  /**
   * Function that is bound to the keydown event of text areas to limit
   * the number of lines allowed to the number of rows in the text area.
   */
  limitTextareaLine: function(e) {
    if(e.keyCode == 13 && $(this).val().split("\n").length > $(this).attr('rows')) {
      return false;
    }
    return true;
  },

  /**
   * Given a submit event on a form and the form.  Prompt the user
   * with the a message from the form, if the user accepts submit the form
   * and if they don't, prevent the form submission.
   */
  confirmFormSubmit: function(event, form) {
    var answer = confirm($(form).find("input[name='confirm_message']").val());
    if (answer) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  },

  /**
   * Auto fill a text box based on the data defined in the event targets
   * metadata.  The metadata for the element must contain an attribute called
   * inputName.  The target must be contained in a element of class auto_fill_input
   * which must also contain an element auto_fill_text.
   */
  autoFillTextBox: function (event) {
    var $element = $(event.target);
    var inputName = $element.metadata().inputName;
    var text = $element.parents('.auto_fill_input').find('.auto_fill_text').html();
    $element.parents('form').find('input[name="' + inputName + '"]').val(text);
  },

  /**
   * Function that retrieves executes AJAX queries to update single values
   * in the page.
   *
   * Container = .ajax_get_single_value
   * Query String = .ajax_get_single_value span.ajax_link
   * Value Update = .ajax_get_single_value span.ajax_value
   */
  getAjaxSingleValues: function () {
    $(".ajax_get_single_value").each(function(index, element) {
      $.get($(element).find('span.ajax_link').html(), [],
          function(data) {
            utils.updateAjaxSingleValue(element, data.value);
          }, "json");
    });
  },

  /**
   * Update the single ajax value found inside element with value
   */
  updateAjaxSingleValue: function(element, value) {
    $(element).find('span.ajax_value').html(value);
  },

  setAllCheckboxes: function (element, state) {
    $(element).closest('form').find('input[type="checkbox"]').attr("checked", state);
  },

  /**
   * Display the number of characters remaining for an element
   */
  setCharactersRemainingDisplay: function (element) {
    var $element = $(element);
    var maxChars = $element.attr('maxlength');
    var currentChars = element.value.length;
    var $label = $element.parents('li').find('label');

    $label.find('span.characters_remaining').html(maxChars - currentChars);
    if ((maxChars - currentChars) < 2) {
      $label.addClass('too_long');
    } else {
      $label.removeClass('too_long');
    }
  },

  /**
   * Bind events to track changes to any textarea's that have the maxlength
   * attribute set and have a span.characters_remaining in their label.
   */
  bindCharactersRemainingEvents: function () {
    var setCharactersRemainingDisplay = utils.setCharactersRemainingDisplay;

    $("textarea[maxlength]").each(function() {
      if ($(this).parents('li').find('label span.characters_remaining').length > 0) {
        $(this).change(() => setCharactersRemainingDisplay(this));
        $(this).keyup(() => setCharactersRemainingDisplay(this));
        setCharactersRemainingDisplay(this);
      }
    });
  },

  /**
   * TODO move to a types module
   */
  isString : function(s) {
    return Object.prototype.toString.call(s) == '[object String]';
  }
};

export default utils;
