import Constants from './constants';


const courseForm = {
  /**
   * If the selected category is birthday display the birthday
   * dialog.
   */
  showBirthdayMessage : function () {
    // FIXME(digia): Constants doesn't appear to ever get defined on PLAYWELL?
    if ($('#category_id').val() == Constants.CATEGORY_ID_BIRTHDAY)  {
      var pos = $('#category_id').position();
      var docViewTop = $(window).scrollTop();
      $("#switch_birthday").dialog('destroy');
      $("#switch_birthday").dialog({ 'position': [pos.left, pos.top - docViewTop] });
      $("#switch_birthday").dialog("open");
    }
  },

  paypalOnlyEffects : function () {
    if ($('#is_only_paypal_registration').prop('checked')) {
      $('#regular_enrollments').prop('disabled', true);
      $('#regular_enrollments').val('');
    } else {
      $('#regular_enrollments').prop('disabled', false);
    }
  }
};

export default courseForm;
