import deburr from 'lodash/deburr';

import google from '../google';
import utils from './utils';


const geocode = {
  initialized: false,

  geocoder : null,
  map : null,
  marker : null,
  geocodeResults: null,

  initialize : function() {
    if ( ! google) {
      console.error(`Google Map's API is required to use PLAYWELL.geocoder`);
      return;
    }

    geocode.geocoder = new google.maps.Geocoder();

    const latlng = new google.maps.LatLng(37.977052, -122.555973);
    const mapOptions = {
      zoom: 12,
      center: latlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    geocode.map = new google.maps.Map(document.getElementById('map_canvas'), mapOptions);

    geocode.initialized = true;
  },

  codeAddress : function(selectedFormattedAddress) {
    var address = geocode.constructAddress();

    geocode.clearResults();

    geocode.geocoder.geocode( {
      'address': address
    }, function(results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        geocode.geocodeResults = results;
        $('#number_of_geocode_results').text(results.length + " location"
          + (results.length > 1 ? 's' : '') + " found.");

        for (var i in geocode.geocodeResults) {
          var formatted_address_raw = geocode.geocodeResults[i]["formatted_address"];
          var formatted_address = deburr(formatted_address_raw);
          var $option = $("<option></option>").prop("value", i).text(formatted_address);
          if (geocode.geocodeResults[i]["formatted_address"] == selectedFormattedAddress) {
              $option.prop("selected", true);
          }

          $("select#geocode_results").append($option);
        }

        geocode.displaySelectedGeocode();
      } else {
        geocode.displayGeocodeFailureMessage(status);
      }
    });
  },

  displayGeocodeFailureMessage : function(status) {
    switch (status) {
      case google.maps.GeocoderStatus.OVER_QUERY_LIMIT:
        alert ("An error occured in geocoding. The query limit for the geocoding service has been reached, please contact support ASAP.");

        break;
      case google.maps.GeocoderStatus.ZERO_RESULTS:
        if ($("#is_location_address_overridden").is(":checked")) {
          alert("An error occured in geocoding. Please make sure you have correctly entered the address.  "
          + "It may be helpful to use Google Maps "
          + "to identify an address that can be geocoded.")
        } else {
          alert("An error occured in geocoding. Please make sure you have correctly entered the address.  "
          + "You can also try overriding the address fields and by specifing "
          + "an override address.")
        }

        break;
      default:
        alert ("An error occured in geocoding.  Geocoding Status Message: " + status);
        break;
    }
  },

  displaySelectedGeocode : function () {
    var result = geocode.geocodeResults[$("select#geocode_results").val()];

    if (!geocode.map.getBounds().contains(result.geometry.location)) {
      //recenter the map if the marker isn't in the bounds of the map.
      geocode.map.setCenter(result.geometry.location);
    }

    if (geocode.marker) {
      geocode.marker.setMap(null);
    }

    geocode.marker = new google.maps.Marker({
      map: geocode.map,
      position: result.geometry.location
    });
  },

  constructAddress : function() {
    var isAddressOverridden = $("#is_location_address_overridden").is(":checked");
    if (isAddressOverridden) {
        return $("#location_address_override").val();
    } else {
      var address = geocode.constructAddressString();
    }
    return address;
  },

  constructAddressString : function() {
    var street1 = $("input#street1").val();
    var street2 = $("input#street2").val();
    var city = $("input#city").val();
    var province = $("select#province :selected").text()
    var postalCode = $("input#postal_code").val();
    var address = '';
    address += street1;

    if (utils.isString(street2) && (street2.length > 0)) {
      address += ', ' + street2;
    }

    address += ", " + city + ', ' + province + ' ' + postalCode;

    return address;
  },

  appendGeocodeDataToForm : function () {
    var geocodeFormattedAddress = "";
    var lat = 0;
    var lng = 0;
    var selectedGeocodeResult = $("select#geocode_results").val();

    if ((selectedGeocodeResult != null) && (selectedGeocodeResult != "")) {
      var selectedResult = geocode.geocodeResults[selectedGeocodeResult];
      geocodeFormattedAddress = selectedResult.formatted_address;
      lat = selectedResult.geometry.location.lat();
      lng = selectedResult.geometry.location.lng();
    }

    $('input#geocode_formatted_address').val(deburr(geocodeFormattedAddress));

    $('<input />').attr('type', 'hidden')
      .attr('name', 'geocode_location_lat')
      .attr('value', lat)
      .appendTo('form.geocode');

    $('<input />').attr('type', 'hidden')
      .attr('name', 'geocode_location_lng')
      .attr('value', lng)
      .appendTo('form.geocode');
  },

  loadMapFromForm : function () {
    var formattedAddress = $("input#geocode_formatted_address").val();
    if (formattedAddress.length > 0) {
      geocode.codeAddress(formattedAddress);
    }
  },

  controlIsGeocodingVisisble : function() {
    var isSkipped = $('#is_skip_geocode').is(':checked');
    if (isSkipped) {
      $('#geocode_location').addClass('is_skip_geocode');
    } else {
      $('#geocode_location').removeClass('is_skip_geocode');
      geocode.initialize();
    }
  },

  controlAddressOverride : function () {
    var isOverride = $('#is_location_address_overridden').is(':checked');

    if (isOverride) {
      $('#geocode_location').removeClass('location_address_not_overridden');
      if (jQuery.trim($("#location_address_override").val()).length == 0) {
          $("#location_address_override").val(geocode.constructAddressString());
      }
    } else {
      $('#geocode_location').addClass('location_address_not_overridden');
    }
  },

  changeEventLocationOverridden : function () {
    geocode.clearResults();
    geocode.controlAddressOverride();
  },

  clearResults : function () {
    $("select#geocode_results").empty();

    if (geocode.marker !== null) {
      geocode.marker.setMap(null);
    }

    geocode.marker = null;
    geocode.geocodeResults = null;
    $('#number_of_geocode_results').text("");
  }
};

export default geocode;
