import Constants from './constants';
import Utils from './utils';
import Security from './security';
import Geocode from './geocode';
import CourseForm from './course-form';
import VideoTraining from './video-training';
import OtherHoursCache from './other-hours-cache';
import WebPublishing from './web-publishing';
import TimeCard from './time-card';
import CourseCache from './course-cache';
import Birthdays from './birthday';
import ResourceTypes from './resource-types';
import CourseInvoice from './course-invoice';


/**
 * The PLAYWELL global namespace object.  If PLAYWELL is already defined, the
 * existing PLAYWELL object will not be overwritten so that defined
 * namespaces are preserved.
 */
const PLAYWELL = {};
PLAYWELL.RESOURCE_TYPE_REGISTER_PLAYWELL = 3;

PLAYWELL.Utils = Utils;
PLAYWELL.geocode = Geocode;
PLAYWELL.Security = Security;
PLAYWELL.VideoTraining = VideoTraining;
PLAYWELL.OtherHoursCache = OtherHoursCache;
PLAYWELL.courseForm = CourseForm;
PLAYWELL.webPublishing = WebPublishing;
PLAYWELL.timeCard = TimeCard;
PLAYWELL.courseCache = CourseCache;
PLAYWELL.Birthdays = Birthdays;
PLAYWELL.resourceTypes = ResourceTypes;
PLAYWELL.courseInvoice = CourseInvoice;

export default PLAYWELL;
