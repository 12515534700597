const birthdays = {
  hideLocation : function() {
    $('#birthday_location').hide();
  },

  showLocation : function() {
    $('#birthday_location').show();
  },

  setLocationVisibility : function() {
    if ($('input.facility:checked').length > 0) {
      birthdays.hideLocation();
      $('#geocode_location').hide();
    } else {
      birthdays.showLocation();
      $('#geocode_location').show();
    }
  },

  /**
   * If the end of the age range is the same as the range fo
   * value for the start of the age range.
   */
  setAgeRangeEnd : function () {
    if ($('#participant_age_range_end').val() == birthdays.ageRangeStart) {
     $('#participant_age_range_end').val($('#participant_age_range_start').val());
    }
    birthdays.ageRangeStart = $('#participant_age_range_start').val();
  },

  /**
   * Stores the value of the currently selected option for the
   * participant_age_range_start select element
   */
  ageRangeStart : ""
};

export default birthdays;
