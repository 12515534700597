const courseCache = {
  ajaxRequest : function() {
    $.getJSON($('form#hidden_form input[name="process_url"]').val(), function(json) {
      if (json.has_dirty_remaining) {
        setTimeout(() => courseCache.ajaxRequest(), 50);
      } else {
        window.location.reload();
      };
    });
  },

  ajaxUpdateCount : function() {
    $.getJSON($('form#hidden_form input[name="progress_url"]').val(), function(json) {
        var v = 100 - ((json.dirty_count / $('.total_courses').html()) * 100);
        $("#progressbar").progressbar('option', 'value', Math.max(v, 5));

        if (json.dirty_count > 0) {
            window.setTimeout(() => courseCache.ajaxUpdateCount(), 500);
        }
    });
  }
};

export default courseCache;
