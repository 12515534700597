module.exports = {
  'assign': require('./assign'),
  'assignIn': require('./assignIn'),
  'assignInWith': require('./assignInWith'),
  'assignWith': require('./assignWith'),
  'at': require('./at'),
  'create': require('./create'),
  'defaults': require('./defaults'),
  'defaultsDeep': require('./defaultsDeep'),
  'entries': require('./entries'),
  'entriesIn': require('./entriesIn'),
  'extend': require('./extend'),
  'extendWith': require('./extendWith'),
  'findKey': require('./findKey'),
  'findLastKey': require('./findLastKey'),
  'forIn': require('./forIn'),
  'forInRight': require('./forInRight'),
  'forOwn': require('./forOwn'),
  'forOwnRight': require('./forOwnRight'),
  'functions': require('./functions'),
  'functionsIn': require('./functionsIn'),
  'get': require('./get'),
  'has': require('./has'),
  'hasIn': require('./hasIn'),
  'invert': require('./invert'),
  'invertBy': require('./invertBy'),
  'invoke': require('./invoke'),
  'keys': require('./keys'),
  'keysIn': require('./keysIn'),
  'mapKeys': require('./mapKeys'),
  'mapValues': require('./mapValues'),
  'merge': require('./merge'),
  'mergeWith': require('./mergeWith'),
  'omit': require('./omit'),
  'omitBy': require('./omitBy'),
  'pick': require('./pick'),
  'pickBy': require('./pickBy'),
  'result': require('./result'),
  'set': require('./set'),
  'setWith': require('./setWith'),
  'toPairs': require('./toPairs'),
  'toPairsIn': require('./toPairsIn'),
  'transform': require('./transform'),
  'unset': require('./unset'),
  'update': require('./update'),
  'updateWith': require('./updateWith'),
  'values': require('./values'),
  'valuesIn': require('./valuesIn')
};
