const videoTraining = {
  renameCategory : function($link) {
    var newName = prompt("New Name", "Enter the new category name");
    if (newName != null) {
      window.location = $link.attr("href") + newName;
    }
  },

  insertCategory : function($link) {
    var newName = prompt("Category Name", "Enter the category name");
    if (newName != null) {
      window.location = $link.attr("href") + newName;
    }
  },

  removeCategoriesNotSelected : function() {
    var currentDefault = $('select#default_category_id').val();
    $('select#default_category_id option').remove();
    $('input.video_cat:checked').each(function() {
      var $this = $(this);
      var catHtmlId = $this.attr('id');
      var catId = catHtmlId.substring(catHtmlId.lastIndexOf('-') + 1);
      var catName = $this.attr('alt');
      var option;

      if (catId == currentDefault) {
          option = '<option selected="selected" value="' +
                  catId + '">' + catName + '</option>';
      } else {
          option = '<option value="' +
                  catId + '">' + catName + '</option>';
      }

      $('select#default_category_id').append(option);
    });
  },

  /**
   * Returns true if value is a valid number, and false otherwise
   * @param {String} value
   * @return Boolean
   */
  addRelatedVideo : function() {
    var newRelated = $('#related_videos').find('li:nth-child(' +
      ($('#related_videos li').size() - 1) + ')').clone();

    //Remove any id's from the children of the new li
    newRelated.children().removeAttr('id');
    //if any of the selects have an option that is selected remove that selection
    newRelated.find('option').removeAttr('selected');
    newRelated.find('[tabindex]').each(function() {
      $(this).attr('tabindex', parseInt($(this).attr('tabindex')) + 2);
    });

    //insert the new li
    $('#add_related').before(newRelated);

    return false;
  }
};

export default videoTraining;
