import PLAYWELL from '../playwell';


$(document).ready(function() {
  /******************** Set Focus *****************************/

  $("[tabindex=1]").focus()

  /******************** Delete Links *****************************/

  $("a.delete").click(function(event) {
    event.preventDefault();
    var answer = confirm($(this).find("span.confirm").text());
    if (answer) {
      window.location = $(this).attr('href');
      return false;
    } else {
      return false;
    }
  });

  /******************** Auto Fill Text Fields on Click ***************/

  $(".auto_fill_link").click(function (event) {
    PLAYWELL.Utils.autoFillTextBox(event);
  });

  /******************** Submit Forms with confirmation **************/

  $("form.confirm").submit(function(event) {
    return PLAYWELL.Utils.confirmFormSubmit(event, this);
  });

  /********* ATTACH DATE PICKERS ************/

  Date.firstDayOfWeek = 7;
  Date.format = 'mm/dd/yyyy';
  $('.date_input').datePicker({ startDate:'01/01/1996', showYearNavigation: false });

  //replace the text for the calendar buttons with blank spaces so the background image shoes through.
  var datebuttonhtml = '&nbsp;&nbsp;&nbsp;&nbsp;';
  $('.dp-choose-date').html(datebuttonhtml);

  $('.jqmWindow').each(function () {
    if ($(this).parent().hasClass('help_container')) {
      $(this).jqm({ ajax: $(this).find('.url').html(), trigger: $(this).parent() });
    }
  });

  $('.lookup_container img.info').click(function () {
    var container = $(this).parent();
    var id = container.prev().val();
    var urlBase = container.find('.jqmWindow .url').html();
    var url = urlBase + id;
    $('#jqmWindowTemp').remove();
    var newAnchor = container.find('.jqmWindow').clone().attr('id', 'jqmWindowTemp');

    container.append(newAnchor);
    $('#jqmWindowTemp').jqm({ ajax: url }).jqmShow();
  });

  /********************** Internet Explorer Buttons **************************/
  //This allows HTML buttons to behave like standard links.
  //Specifcally this addresses at least to known issues:
  //-IE doesn't allow you to have clickable buttons not in forms
  //-FF submits the form in addition to following the href
  $("button.linked").click(function(event){
    event.preventDefault();
    window.location = $(this).parent().attr('href');
  });

  //Removes the click events bound to the buttons in delete links.
  //This fixes a bug in FF where delete links that contained linked buttons
  //would move directly through the confirmation input box without user input
  $("a.delete").find("button.linked").unbind('click');

  /*
   * Add a custom parser to the table sorter to fix issues sorting currency
   * fields that have additional content in them.  Specifically this
   * addresses the dashboard columns which have spans in them.
   */
  if ($.tablesorter) {
    $.tablesorter.addParser({
      id: "currency_custom",
      is: function (s) {
        return false;
      },
      format: function (s) {
        return $.tablesorter.formatFloat(s.replace(new RegExp(/[^-0-9.]/g), ""));
      },
      type: "numeric"
    });
  }

  //sort tables with the datagrid class
  $(".datagrid").tablesorter({
    widgets: ['zebra']
  });

  /********************** Tool Tips **************************/

  $(".tip").tooltip({ showURL: false });

  $('.info_tip').tooltip({
    bodyHandler: function() {
      return $(this).find('span.tip_body').html();
    },
    showURL: false
  });

  $('.general_unselect_all').click(function () {
    PLAYWELL.Utils.setAllCheckboxes(this, false);
  });

  $('.general_select_all').click(function () {
    PLAYWELL.Utils.setAllCheckboxes(this, true);
  });

  PLAYWELL.Utils.autoSubmitSelectForms();
  PLAYWELL.Utils.highlightRowOnLink();
  PLAYWELL.Utils.getAjaxSingleValues();
  PLAYWELL.Utils.bindCharactersRemainingEvents();

  //Bind the keydown event of text areas that should have their
  //number of rows limited to the function that will prohibit extra lines
  $('textarea.limit_rows').keydown(PLAYWELL.Utils.limitTextareaLine);

  /********************** Column Chooser **************************/

  /**
   * Hides/Shows the column coressponding to checkbox name attribute.
   * @param {jQuery} checkbox
   */
  function toggleColumn(checkbox) {
    var name = checkbox.prop("name");
    var state =  checkbox.is(':checked');
    var tName = $("form#column_select input[name='table_name']").val();

    if (state) {
      $("table." + tName).removeClass("hide_" + name);
    } else {
      $("table." + tName).addClass("hide_" + name);
    }

    $("table." + tName).each (function () {
      var visibleHeaderRows = $(this).find("thead tr:first th:visible").length;
      var visibleFooterRows = $(this).find("tfoot tr:last td:visible").length;
      $(this).find(".col_total_label").each(function() {
        this.colSpan = (visibleHeaderRows - visibleFooterRows + 1);
      });
    });
  }

  //Bind the checkbox click events to toggleColumn
  $("form#column_select input[type='checkbox']").click(function() {
    toggleColumn($(this));
  });

  //On page load hide the columns not selected
  $("form#column_select [type=checkbox]:not(:checked)").each(function() {        
    toggleColumn($(this));
  });


  //Expand/Collapse the column chooser checkboxes
  $("button#choose_columns").click(function() {
    if ($("form#column_select").is(':visible')) {
      $("button#choose_columns span").html("Choose Columns");
      $(this).parent().removeClass("column_chooser_active");
    } else {
      $("button#choose_columns span").html("Hide Column Chooser");
      $(this).parent().addClass("column_chooser_active");
    }

    $("form#column_select").toggle(300);
  });


  /**
   * Submit the selected column form to server and ignore the response
   */
  function postColumnData() {
    var url = $("form#column_select").attr("action");
    var tName = $("form#column_select input[name='table_name']").val();

    //TODO move the table_name to a hidden form element
    var data = {table_name: tName};
    $("form#column_select input[type='checkbox']").each(function() {
      var name = $(this).attr("name");
      var state =  (this.checked === true);
      data[name] = state;
    });

    $.post(url, data, function() {});
  }

  //When an element of the form is clicked submit the form on a
  //1 second delay.  The delay allows aggregating of many changes in one
  //request.
  $("form#column_select").click(function() {
    if (PLAYWELL.hasOwnProperty('columnTimoutsArray')) {
      //If there are timeouts waiting to be submitted clear those.
      for (var i = 0; i < PLAYWELL.columnTimoutsArray.length; i++) {
        clearTimeout(PLAYWELL.columnTimoutsArray[i]);
      }
    }

    PLAYWELL.columnTimoutsArray = [];
    PLAYWELL.columnTimoutsArray.push(setTimeout(postColumnData, 1000));
  });

  //If the user hovers over the label or the checkbox underline the label.
  $("form#column_select label, form#column_select input[type='checkbox']").hover(function() {
    $(this).parent().addClass("hovered");
  }, function() {
    $(this).parent().removeClass("hovered");
  });
});
