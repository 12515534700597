const courseInvoice = {
  /**
   * Function serailizes the #course_invoice_adjustment form data
   * and submits it in AJAX request to the link embedded in
   * #computed_course_value_link. Then updates the span.computed_course_value
   * with the returned value.
   */
  setCourseValueForInvoiceAdjustment : function () {
    $.post($("#computed_course_value_link").html(), $("#course_invoice_adjustment").serialize(),
        function(data){
            $('form#course_invoice_adjustment span.computed_course_value').html(data.compute_course_value);
        }, "json");
  }
};

export default courseInvoice;
