import utils from './utils';


const webPublishing = {
  //TODO DOC
  actionTypeDisplay : function () {
    var actionType = $('#course_web_publishing_action_type_id').val();
    $('.action_type_dependant :input').attr("disabled", true);
    $("fieldset#web_publishing")
      .removeClass("action_type_1")
      .removeClass("action_type_2")
      .removeClass("action_type_3")
      .removeClass("action_type_4")
      .addClass("action_type_" + actionType);
    $('li.action_type_' + actionType + ' :input').attr("disabled", false);

    //Set the remaining characters on any enabled text area
    $("textarea:enabled[maxlength]").each(function() {
      utils.setCharactersRemainingDisplay(this);
    });
  },

  linkCommentRequired : function () {
    var isRestricted = $("input[name='is_restricted_registration']:visible").is(':checked');

    if (isRestricted) {
      $("textarea[name='action_url_comment']").parents('li').find('label').removeClass('not_required').addClass('required');
    } else {
      $("textarea[name='action_url_comment']").parents('li').find('label').removeClass('required').addClass('not_required');
    }
  },

  copyCommonFields : function () {
    var fieldName = this.name;

    if (this.type && this.type === 'checkbox') {
      $("[name='" + fieldName + "']").prop('checked', this.checked);
    } else {
      var t = $("[name='" + fieldName + "']:visible").val();
      $("[name='" + fieldName + "']").val(t);
    }
  },

  /*
   * When the for_website check box is checked display the additional web
   * publishing fields.  When it's not checked hide all the web publishing
   * fields but the for_website field.
   */
  controlVisibilityWebPublishing : function() {
    var forWebsite = $('#for_website:enabled').is(':checked');
    var isClientForm = $("#for_website").length == 0;

    if (forWebsite || isClientForm) {
      $('#web_publishing').removeClass('not_published');
    } else {
      $('#web_publishing').addClass('not_published');
    }
  },

  clientWebPublishingData : {
    'course_web_publishing_action_type_id' : null,
    'action_url' : null,
    'action_url_comment' : null,
    'registration_instructions' : null,
    'email_registration_description' : null,
    'phone_registration_description' : null,
    'is_restricted_registration' : null
  },

  setWebPublishingToClientDefaults : function () {
    for (var field in webPublishing.clientWebPublishingData) {
      if (webPublishing.clientWebPublishingData.hasOwnProperty(field)) {

        var $element = $('[name="' + field + '"]');
        if ($element.is(':checkbox')) {
          var value = webPublishing.clientWebPublishingData[field];
          if (utils.isString(value)) {
            value = parseInt(value);
          }

          $element.prop('checked', value);

          } else if ($element.is('select')) {
            $element.find('option[value="' + webPublishing.clientWebPublishingData[field] + '"]').prop('selected', true);

          } else {
            $element.each(function () {
              $(this).val(webPublishing.clientWebPublishingData[field]);
            });
          }
      }
    }

    webPublishing.actionTypeDisplay();
    webPublishing.linkCommentRequired();
  },

  setWebPublishingText : function () {
    $("ul#client_web_publishing_defaults")
      .removeClass()
      .addClass('course_web_publishing_action_type_id' + webPublishing.clientWebPublishingData['course_web_publishing_action_type_id']);

    for (var field in webPublishing.clientWebPublishingData) {
      if (webPublishing.clientWebPublishingData.hasOwnProperty(field)) {
        var text = webPublishing.clientWebPublishingData[field];
        if (field == 'is_restricted_registration') {
          if (parseInt(text)) {
            text = "Yes";
          } else {
            text = "No"
          }

        } else if (field == 'course_web_publishing_action_type_id') {
          text = $('#course_web_publishing_action_type_id option[value="' +
            webPublishing.clientWebPublishingData['course_web_publishing_action_type_id'] + '"]').text();
        }

        $("ul#client_web_publishing_defaults span." + field).html(text);
      }
    }
  },

  retrieveClientWebPublishingDefault : function () {
    var selectedClientId = $("select#client_id").val();
    if (!isNaN(parseInt(selectedClientId))) {
      $.getJSON($('#base_url').val() + "/clients/get.json.data?client_id=" + selectedClientId + '&jsoncallback=?',
        function(data) {
          for (var field in webPublishing.clientWebPublishingData) {
              if (webPublishing.clientWebPublishingData.hasOwnProperty(field)) {
                  webPublishing.clientWebPublishingData[field] = data[field];
              }
          }
          webPublishing.setWebPublishingText();
          webPublishing.setIsClientDefaultsEnabled(data['is_valid_web_publishing']);
      });
    }
  },

  /**
   * Enables or Disables the is_web_publishing_client_defaults checkbox
   * depending on the value of isEnabled.
   *
   * @param {Boolean} isEnabled
   *
   */
  setIsClientDefaultsEnabled : function (isEnabled) {
    if (isEnabled) {
      $("#is_web_publishing_client_defaults").prop("disabled", false);
      //Hide the hint text for why the checkbox is disabled
      $("#is_web_publishing_client_defaults").parents("li").find('span.help_disabled').hide();
    } else {
      $("#is_web_publishing_client_defaults").prop("disabled", true);
      //Show the hint text for why the checkbox is disabled
      $("#is_web_publishing_client_defaults").parents("li").find('span.help_disabled').show();
      if ($("#is_web_publishing_client_defaults").is(":checked")) {
        //Copy the defaults to the course level fields
        webPublishing.setWebPublishingToClientDefaults();
      }
    }

    webPublishing.controlVisibilityCourseFieldsWithClientDefaults();
  },

  /**
   * Retrieve the client defaults for the currently selected client from
   * the server.
   */
  initializeClientWebPublishingDefault : function () {
    var $clientSelect = $("select#client_id");
    if (($clientSelect.length > 0) && ($clientSelect.val().length > 0)) {
      webPublishing.retrieveClientWebPublishingDefault();
    }
  },

  /**
   * Event handler for the change in state of the 
   * is_web_publishing_client_defaults checkbox.  If no longer using
   * the client defaults copy those defaults to the form fields.
   */
  changeClientDefaultSelection : function () {
    webPublishing.controlVisibilityCourseFieldsWithClientDefaults();
    if (!$("#is_web_publishing_client_defaults:enabled").is(":checked")) {
      webPublishing.setWebPublishingToClientDefaults();
    }
  },

  /**
   * If the is_web_publishing_client_defaults checkbox is checked then
   * hide the fields that override the client defaults.  If not checked
   * then show these fields.
   */
  controlVisibilityCourseFieldsWithClientDefaults : function () {
    if ($("#is_web_publishing_client_defaults:enabled").is(":checked")) {
      $("fieldset#web_publishing").addClass('use_client_defaults');
    } else {
      $("fieldset#web_publishing").removeClass('use_client_defaults');
    }
  },

  retrieveIsLocationGeocoded : function () {
    var selectedClientLocationId = $("select#client_location_id").val();
    var $forWebsiteListItem = $('#for_website').parents('li');
    $forWebsiteListItem.
            removeClass('no_location').
            removeClass('no_geocode').
            removeClass('geocode').
            addClass('loading');

    if (!isNaN(parseInt(selectedClientLocationId))) {
      $.getJSON($('#base_url').val() + "/infolookup/client.location.data?client_location_id=" + selectedClientLocationId,
          function(data) {
            if (data['is_skip_geocode']) {
                $('#for_website').prop('disabled', true);
                var $link = $forWebsiteListItem.find('span.no_geocode a');
                $link.prop('href', webPublishing.replaceUrlParam($link.prop('href'), 'client_location_id', selectedClientLocationId));
                $link.text($("select#client_location_id option:selected").text());
                $forWebsiteListItem.addClass('no_geocode').
                  removeClass('loading');

            } else {
              $('#for_website').prop('disabled', false);
              $forWebsiteListItem.addClass('geocode').
                removeClass('loading');
            }

            webPublishing.controlVisibilityWebPublishing();
          });

    } else {
      $('#for_website').prop('disabled', true);
      $forWebsiteListItem.addClass('no_location').removeClass('loading');
      webPublishing.controlVisibilityWebPublishing();
    }
  },

  //TODO move to Utils
  //Reference http://stackoverflow.com/a/1091083
  replaceUrlParam : function (url, paramName, paramValue) {
    var additionalUrlParams = "";
    var tempArray = url.split("?");
    var baseURL = tempArray[0];
    var additionalURL = tempArray[1];
    var temp = "";
    if (additionalURL) {
      tempArray = additionalURL.split("&");
      for (i=0; i<tempArray.length; i++){
        if(tempArray[i].split('=')[0] != paramName){
          additionalUrlParams += temp + tempArray[i];
          temp = "&";
        }
      }
    }

    var param_txt = temp + "" + paramName + "=" + paramValue;
    return baseURL + "?" + additionalUrlParams + param_txt;
  }
};

export default webPublishing;

